import { Action, Dispatch, ThunkAction, UnknownAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Box, Item, ItemMap, ItemToBoxAction } from '../../Types/Boxes'
import { setLoading } from './systemSlice';
import { AppThunk, RootState } from '../../Types/State';
import { usePopup } from '../../Components/General/PopUpContext';
import { generateClient } from 'aws-amplify/api';
import { createBox, deleteBox, updateBox } from '../../graphql/mutations';
import { CreateBoxInput, DeleteBoxInput, DeleteBoxMutation, ListBoxesQuery, UpdateBoxInput, UpdateBoxMutation } from '../../API';
import { convertBoxForUpdate_GQLToTS, convertBoxList_GQLToTS, convertBox_GQLToTS } from '../../Utilities/Utils';
import { listBoxes } from '../../graphql/queries';

const client = generateClient();

const boxesInitialState: { [key: string] : Box} = {};

const boxesSlice = createSlice({
  name: 'boxes',
  initialState: boxesInitialState,
  reducers: {
    addUpdateBox: (state, action) => {
      const box: Box = action.payload;
      state[box.id] = box; // Will overwrite or add
    },
    addItemToBox: (state, action) => {
      const itemBox: ItemToBoxAction = action.payload;
      const { box, item } = itemBox;
      if (!state[box.id]) {
        console.error("Unable to add item to a box that doesn't exist.")
        return;
      }

      const itemName = item.name;

      if (!state[box.id].items) {
        state[box.id].items = {};
      }

      state[box.id].items![itemName] = item;
    },
    removeBox: (state, action) => {
      const boxId: string = action.payload;
      if (state[boxId]) {
        delete state[boxId];
      }
    },
    removeBoxes: (state, action) => {
      const boxIds: string = action.payload;
      
      if (boxIds === undefined) return;

      for (let boxId of boxIds) {
        if (state[boxId]) {
          delete state[boxId];
        }
      }
    },
    removeItem: (state, action) => {
      const itemBox: ItemToBoxAction = action.payload;
      const { box, item } = itemBox;
      if (!state[box.id]) {
        console.error("Unable to remove item from a box that doesn't exist.")
        return;
      }

      const itemName = item.name;

      if (!state[box.id].items) {
        console.warn("We are removing something but I don't have it.")
        return; // The item isn't here
      }

      delete state[box.id].items![itemName];
    },
    addAllBoxes: (state, action) => {
      const allInfo: { [key: string]: Box } = action.payload;

      for (let id of Object.keys(state)) {
        delete state[id];
      }

      for (let id of Object.keys(allInfo)) {
        state[id] = allInfo[id]
      }
    },
    moveItemToAnotherBox: (state, action) => {
      state = state;
    },
    deleteManyBoxes: (state, action) => {
      const idsToDelete: string[] = action.payload;

      for (let id of idsToDelete) {
        delete state[id];
      };
    }
  }
})

export const { addItemToBox, removeItem, moveItemToAnotherBox } = boxesSlice.actions;
const { addAllBoxes, addUpdateBox, removeBox, removeBoxes } = boxesSlice.actions;

export default boxesSlice.reducer

function wait(time: number = 2000) {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
}

//ThunkAction<void, RootState, unknown, Action<string>>
export function getAllBoxesPersistence(): AppThunk {
  return async (dispatch: Dispatch<UnknownAction>, getState: () => RootState) => {
    console.log("Doing")
    try {
      const res = await client.graphql({
        query: listBoxes
      });

      if (res) {
        const data: ListBoxesQuery = res.data;

        const boxes: Box[] = convertBoxList_GQLToTS(data)

        const result: { [key: string] : Box } = {};
  
        for (let box of boxes) {
          if (box === undefined || box === null) continue;
          result[box.id] = box;
        }
      
        dispatch(addAllBoxes(result));
      }
    } catch (e) {
      console.error("something went wrong", e);
    }
    // Make API call
    
    dispatch(setLoading(false));
  }
}

export function deleteBoxes(ids: string[]): AppThunk {
  return async (dispatch: Dispatch<UnknownAction>, getState: () => RootState) => {
    // Make API call

    const idsRemoved: string[] = [];


    for (let id of ids) {
      try {
        const input: DeleteBoxInput = {
          id
        };

      
        const res = await client.graphql({
          query: deleteBox,
          variables: {
            input
          }
        });

        if (res) {
          const res2: DeleteBoxMutation = res.data;

          if (res2.deleteBox?.id) {
            idsRemoved.push(res2.deleteBox?.id);
          }
        }
      } catch (e) {
        console.warn(id);
        console.error("something went wrong deleting the above box", e);
      } 
    }
    dispatch(removeBoxes(idsRemoved))
  }
}

export function createBoxPersistence(box: Box): AppThunk {
  return async (dispatch: Dispatch<UnknownAction>, getState: () => RootState) => {
    // Make API call
    dispatch(addUpdateBox(box))
    
    const input: CreateBoxInput = {
      ...box,
      items: box.items
        ? Object.values(box.items).map((value) => { return value })
        : null,
    };

    try {
      const res = await client.graphql({
        query: createBox,
        variables: {
          input
        }
      });

      if (res) {
        const newBox: Box | null = convertBox_GQLToTS(res.data);

        if (newBox) {
          dispatch(addUpdateBox(newBox))
        }
      }
    } catch (e) {
      console.error("something went wrong creating box", e);
    } 
  }
}

export function updateBoxPersistence(box: Box): AppThunk {
  return async (dispatch: Dispatch<UnknownAction>, getState: () => RootState) => {
        // Make API call
        const { id, name, from, to, items } = box;
        dispatch(addUpdateBox(box));

        const input: UpdateBoxInput = {
          id,
          name, 
          from,
          to,
          items: items
              ? Object.values(items).filter(v => v !== null && v !== undefined).map((value) => { 
                return {
                  name: value.name,
                  notes: value.notes,
                  quantity: value.quantity,
                  tags: value.tags
              } })
              : null,
        }
    
        try {
          const res = await client.graphql({
            query: updateBox,
            variables: {
              input
            }
          });
    
          if (res) {
            const data: UpdateBoxMutation = res.data;
            
            if (data) {
              dispatch(addUpdateBox(convertBoxForUpdate_GQLToTS(data)))
            }
          }
        } catch (e) {
          console.error("something went wrong creating box", e);
        } 
  }
}