import { createSlice, Dispatch, PayloadAction, UnknownAction } from '@reduxjs/toolkit';
import { NoteState, Note } from '../../Types/Notes';
import { generateClient } from 'aws-amplify/api';
import { listNotes } from '../../graphql/queries';
import { CreateNoteInput, CreateNoteMutation, DeleteNoteInput, DeleteNoteMutation, ListNotesQuery, UpdateNoteInput, UpdateNoteMutation } from '../../API';
import { setNotesLoaded } from './systemSlice';
import { AppThunk, RootState } from '../../Types/State';
import { createNote, deleteNote, updateNote } from '../../graphql/mutations';

const client = generateClient();
const initialState: NoteState = [];

const noteSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    addNote: (state, action: PayloadAction<Note>) => {
      // Adds a new note to the state
      state.push(action.payload);
    },
    removeNote: (state, action: PayloadAction<string>) => {
      // Removes the note with the given id
      return state.filter(note => note.id !== action.payload);
    },
    updateNoteState: (state, action: PayloadAction<Note>) => {
      // Updates the note with the given id
      return state.map(note => note.id === action.payload.id ? action.payload : note);
    },
    addAllNotes:  (state, action: PayloadAction<Note[]>) => {
      return action.payload;
    }
  }
});

const { addNote, removeNote, updateNoteState, addAllNotes } = noteSlice.actions;

export default noteSlice.reducer;

export function getNotesPersistence(): AppThunk {
  return async (dispatch: Dispatch<UnknownAction>, getState: () => RootState) => {

    
    console.log(`Getting all`);
    try {
      const res = await client.graphql({
        query: listNotes
      });

      if (res) {
        const data: ListNotesQuery = res.data;

        if (!data.listNotes && !data.listNotes!.items) {
          return null;
        }

        const resNotes = data.listNotes!.items;

        const notes: Note[] = resNotes
        .filter(note => note !== null && note !== undefined) // Filter out null and undefined values
        .map(note => {
          // TypeScript now knows 'note' is not null or undefined
          const { id, title, category, content } = note!;
          return {
            id,
            title,
            category,
            content
          };
        });
      
        dispatch(addAllNotes(notes));
      }
    } catch (e) {
      console.error("something went wrongv getting all notes", e);
    }
    // Make API call
    
    dispatch(setNotesLoaded(true));
  }
}

export function createNotePersistence(note: Note): AppThunk {
  return async (dispatch: Dispatch<UnknownAction>, getState: () => RootState) => {

    const input: CreateNoteInput = {
      ...note
    }

    console.log(`Creating ${input}}`);
    try {
      const res = await client.graphql({
        query: createNote,
        variables: {
          input
        }
      });

      if (res) {
        const data: CreateNoteMutation = res.data;

        if (!data.createNote) {
          return null;
        }

        const { id, title, category, content } = data.createNote;
      
        dispatch(addNote( { id, title, category, content }));
      }
    } catch (e) {
      console.error("something went wrong adding a note", e);
    }
  }
}

export function updateNotePersistence(note: Note): AppThunk {
  return async (dispatch: Dispatch<UnknownAction>, getState: () => RootState) => {

    const input: UpdateNoteInput = {
      ...note
    }

    
    console.log(`Updating ${input}}`);
    try {
      const res = await client.graphql({
        query: updateNote,
        variables: {
          input
        }
      });

      if (res) {
        const data: UpdateNoteMutation = res.data;

        if (!data.updateNote) {
          return null;
        }

        const { id, title, category, content } = data.updateNote;
      
        dispatch(updateNoteState( { id, title, category, content }));
      }
    } catch (e) {
      console.error("something went wrong updating a note", e);
    }
  }
}

export function deleteNotePersistence(id: string): AppThunk {
  return async (dispatch: Dispatch<UnknownAction>, getState: () => RootState) => {

    const input: DeleteNoteInput = {
      id
    }
    
    console.log(`Deleting ${input}}`);

    try {
      const res = await client.graphql({
        query: deleteNote,
        variables: {
          input
        }
      });

      if (res) {
        const data: DeleteNoteMutation = res.data;

        if (!data.deleteNote) {
          return null;
        }

        const idResult = data.deleteNote.id;
      
        dispatch(removeNote(idResult));
      }
    } catch (e) {
      console.error("something went wrong removing a note", e);
    }
  }
}