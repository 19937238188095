import { createSlice } from '@reduxjs/toolkit'
import { SystemState } from '../../Types/System';

const initialState: SystemState = {
  loading: true,
  user: "",
  notesLoaded: false
};

const systemSlice = createSlice({
  name: 'boxes',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setNotesLoaded: (state, action) => {
      state.notesLoaded = action.payload.notesLoaded;
    }
  }
})

export const { setLoading, setUser, setNotesLoaded } = systemSlice.actions;

export default systemSlice.reducer