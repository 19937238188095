import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface PopupCardProps {
  open: boolean;
  message: string;
  severity: 'error' | 'info';
  timeout: number | null;
  handleClose: () => void;
}

export const PopupCard: React.FC<PopupCardProps> = ({
  open,
  message,
  severity,
  timeout,
  handleClose,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={timeout ? timeout : null}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert
        severity={severity}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
