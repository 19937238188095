import { CreateBoxInput, CreateBoxMutation, ListBoxesQuery, UpdateBoxMutation } from "../API";
import { Box, Item } from "../Types/Boxes";
import { Box as ApiBox } from "../API"

export function convertBoxList_GQLToTS(data: ListBoxesQuery): Box[] {
  const receivedBoxes = data.listBoxes;

  if (receivedBoxes === undefined || receivedBoxes === null) {
    throw new Error("Invalid data type");
  }

  const boxes: Box[] = receivedBoxes.items?.map(convertBox).filter(box => box !== null) as Box[];
  return boxes;
}

function convertBox(receivedBox: ApiBox | null) {
  if (receivedBox === null) {
    return null;
  }

  if (!receivedBox) {
    throw new Error("Invalid data type");
  }

  const newBox: Box = {
    id: receivedBox.id,
    name: receivedBox.name,
    from: receivedBox.from,
    to: receivedBox.to,
    items: {}
  }

  if (receivedBox.items) {
    for (let item of receivedBox.items) {
      if (!item) continue;
      if (item.name === null || item.name === undefined)  continue;
      
      const newItem: Item = {
        ...item,
        tags: item.tags?.map(t => t ? t : "1")
      }

      newBox.items![newItem.name.toLowerCase()] = newItem;
    }
  }
  return newBox;
}

export function convertBox_GQLToTS(data: CreateBoxMutation | null): (Box | null) {

  if (data === null) {
    return null;
  }

  const  receivedBox = data.createBox;
  if (receivedBox === null || receivedBox === undefined) {
    return null;
  }

  return convertBox(receivedBox);
}


export function convertBoxForUpdate_GQLToTS(data: UpdateBoxMutation | null): (Box | null) {

  if (data === null) {
    return null;
  }

  const receivedBox = data.updateBox;
  if (receivedBox === null || receivedBox === undefined) {
    return null;
  }

  return convertBox(receivedBox);
}