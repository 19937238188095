import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import { v4 as uuidv4 } from 'uuid';
import {MDXEditor, headingsPlugin, quotePlugin, listsPlugin, thematicBreakPlugin, toolbarPlugin, UndoRedo, BoldItalicUnderlineToggles, MDXEditorMethods, linkPlugin, imagePlugin } from '@mdxeditor/editor';
import { TextField, Button, Paper, Typography } from '@mui/material';

interface NoteProps {
  id?: string;
  title?: string;
  category?: string;
  content?: string;
  onSave: (id: string, title: string, category: string, content: string) => void;
  onDelete: (id: string | undefined) => void;
  close: () => void;
}

const NoteComponent: React.FC<NoteProps> = ({ id, title = '', category = '', content = '', onSave, onDelete, close }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableTitle, setEditableTitle] = useState(title);
  const [editableCategory, setEditableCategory] = useState(category);
  const [editableContent, setEditableContent] = useState(content);
  const mdxEditorRef = useRef<MDXEditorMethods>(null);

  const isNewNote = !id;
  let noteId: string;

  if (id === null || id === undefined || id === '') {
    noteId = uuidv4();   
  } else {  
    noteId = id;
  }

  useEffect(() => {
    if (isNewNote) {
      setIsEditing(true);
    }
  }, [isNewNote]);

  const handleSave = () => {
    if (editableTitle === '' || editableContent === '') {
      alert("Please make sure a title and content have been added.")
      return;
    }

    onSave(noteId, editableTitle, editableCategory, editableContent);
    if (isNewNote) {
      setEditableTitle('');
      setEditableCategory('');
      setEditableContent('');
    } else {
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    if (isNewNote) {
      close();
    } else {
      setEditableTitle(title);
      setEditableCategory(category);
      setEditableContent(content);
      setIsEditing(false);
    }
  };

  const handleContentChange = (value: string) => {
    setEditableContent(value);
    if (mdxEditorRef.current) {
      mdxEditorRef.current.setMarkdown(value); // Call the method to update the markdown
    }
  }

  return (
    <div style={{ maxWidth: '100vw' }}>
      {isEditing ? (
        <>
<TextField
            fullWidth
            variant="outlined"
            value={editableTitle}
            onChange={(e) => setEditableTitle(e.target.value)}
            placeholder="Note Title"
            margin="normal"
          />
          <TextField
            fullWidth
            variant="outlined"
            value={editableCategory}
            onChange={(e) => setEditableCategory(e.target.value)}
            placeholder="Category"
            margin="normal"
          />
          <TextField
            fullWidth
            multiline
            variant="outlined"
            value={editableContent}
            onChange={(e) => handleContentChange(e.target.value)}
            placeholder="Enter markdown content"
            margin="normal"
            maxRows={8}
          />
          <Typography variant='h5'>
            Rendered Output
          </Typography>
          <Paper elevation={3} style={{padding: "20px"}}>
          <MDXEditor
            ref={mdxEditorRef}
            markdown={editableContent}
            plugins={[headingsPlugin(), quotePlugin(), listsPlugin(), thematicBreakPlugin(), linkPlugin(), imagePlugin()]}
            // readOnly={true}
          />
          </Paper>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="contained" onClick={handleCancel}>
            Cancel
          </Button>

        </>
      ) : (
        <>
          <h2>{title}</h2>
          <p>Category: {category}</p>
          {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
          <Paper elevation={3} style={{padding: "20px"}}>
          <MDXEditor
            markdown={editableContent}
            onChange={setEditableContent}
            plugins={[headingsPlugin(), quotePlugin(), listsPlugin(), thematicBreakPlugin(), linkPlugin(), imagePlugin()]}
            readOnly={true}
          />
          </Paper>
          <button onClick={() => setIsEditing(true)}>Edit</button>
          <button onClick={() => onDelete(id)}>Delete</button> {/* Add this delete button */}
          <button onClick={close}>Close</button> {/* Add this delete button */}
        </>
      )}
    </div>
  );
};

export default NoteComponent;
