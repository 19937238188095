import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import BoxesCardsDisplays from '../../Components/Boxes/BoxesCardsDisplay'; // Adjust the import path as needed
import { Box } from '../../Types/Boxes';
import { RootState } from '../../Types/State';
import BoxesTable from '../../Components/Boxes/BoxesTable';
import { deleteBoxes } from '../../Redux/reducers/boxesSlice';

const BoxesList: React.FC = () => {
    const boxes: { [key: string]: Box } = useSelector((state: RootState) => state.boxes)

    const dispatch = useDispatch();

    const handleDeleteBoxes = (ids: string[]) => {
        dispatch(deleteBoxes(ids));
        return true;
    };

    return (
        <div>
            <BoxesTable boxes={boxes} deleteBoxes={handleDeleteBoxes} />
        </div>
    );
};

export default BoxesList;
