import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from "react-router-dom";
import { MenuItem } from '..';
import { AuthUser } from 'aws-amplify/auth';
import { UseAuthenticator } from '@aws-amplify/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Types/State';
import { getAllBoxesPersistence } from '../Redux/reducers/boxesSlice';
import { Button, CircularProgress, Grid } from '@mui/material';
import { SystemState } from '../Types/System';
import { createNotePersistence, deleteNotePersistence, getNotesPersistence, updateNotePersistence } from '../Redux/reducers/notesSlice';
import { Note } from '../API';
import NotesDrawer from './Notes/NoteDrawer';
import NoteComponent from './Notes/Note';


const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type Props = {
    children: React.ReactNode;
    menuItems: MenuItem[];
    user?: AuthUser;
    signOut?: UseAuthenticator["signOut"];
};

export default function Navigation({ children, menuItems, user, signOut }: Props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const notes: Note[] = useSelector((state: RootState) => state.notes)
  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedNoteId, setSelectedNoteId] = React.useState<string>("");
  const [createNote, setCreateNote] = React.useState(false);
  const selectedNote = notes.find(note => note.id === selectedNoteId);

  const location = useLocation();
  
  React.useEffect(() => {
    dispatch(getAllBoxesPersistence());
    dispatch(getNotesPersistence());
  }, [])

  React.useEffect(() => {
    // If the drawer is open and the location changes, close the drawer
    if (drawerOpen || createNote || selectedNote) {
      handleNoteClose();
      setDrawerOpen(false);
    }
  }, [location]);

  const handleNoteSelect = (noteId: string) => {
    setSelectedNoteId(noteId);
    setDrawerOpen(false);
  };

  const handleNoteContentChange = (id: string, title: string, category: string, content: string) => {
    if (createNote) {
      dispatch(createNotePersistence({ id, title, category, content }))
      setCreateNote(false);
      setSelectedNoteId(id);
    } else {
      dispatch(updateNotePersistence({ id, title, category, content }));
    }
  };

  const handleDeleteNote = (noteId: string | undefined) => {
    // Dispatch an action to delete the note
    if (noteId === undefined) {
      return;
    }

    dispatch(deleteNotePersistence(noteId));

    // Reset the selected note ID
    setSelectedNoteId("");
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCreateNewNote = () => {
    setCreateNote(true);
    setDrawerOpen(false);
  };

  const handleNoteClose = () => {
    setDrawerOpen(true);
    setCreateNote(false);
    setSelectedNoteId("");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  interface AddOverlayProps {
    children: React.ReactNode;
  }
  
  const AddOverlay: React.FC<AddOverlayProps> = ( {children} ) => {
    const system: SystemState = useSelector((state: RootState) => state.system)
    const [ellipsis, setEllipsis] = React.useState('');

    React.useEffect(() => {
      if (system.loading) {
        const interval = setInterval(() => {
          setEllipsis(prev => (prev.length < 5 ? prev + '.' : ''));
        }, 500); // Change the interval as needed
  
        return () => clearInterval(interval);
      }
    }, [system.loading]);
  
    if (system.loading) {
      return (
        <Box position="relative" display="inline-block">
          {children}
          <Box
            position="fixed"
            top={0}
            left={0}
            width="100vw"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(0, 0, 0, 0.5)"
            zIndex="modal"
          >
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <CircularProgress />
              </Grid>
              <Grid item>
                <Typography variant="h4" color="primary" align='justify'>
                  Loading{ellipsis}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    }
  
    return (
      <>
      {children}
      </>
    )
  }
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Moving Buddy
          </Typography>
          <Button color="inherit" onClick={toggleDrawer}>
            Notes
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ListItem disablePadding sx={{ display: 'block' }} onClick={signOut}>
          <ListItemButton
              sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              }}
          >
              <ListItemIcon
              sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
              }}
              >
              Sign Out
              </ListItemIcon>
              <ListItemText primary={"Sign Out"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <List>
          {menuItems.map((menuItem) => {
            const { name, icon, path } = menuItem;
            return (
            <Link key={name} to={path} style={{textDecoration: "none", color: "black"}} >
                <ListItem key={name} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                      sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      }}
                  >
                      <ListItemIcon
                      sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                      }}
                      >
                      {icon}
                      </ListItemIcon>
                      <ListItemText primary={name} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
            </Link>
          )})}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <AddOverlay>
          <NotesDrawer
            notes={notes}
            open={drawerOpen}
            onToggle={toggleDrawer}
            onNoteSelect={handleNoteSelect}
            onCreateNewNote={handleCreateNewNote}
          />

          {(selectedNote || createNote) ? (
            <NoteComponent
              id={selectedNote?.id ? selectedNote.id : ''}
              title={selectedNote?.title ? selectedNote.title : ''}
              content={selectedNote?.content ? selectedNote.content : ''}
              category={selectedNote?.category ? selectedNote.category : ''}
              onSave={handleNoteContentChange}
              onDelete={handleDeleteNote}
              close={handleNoteClose}
            />
          ) : children}
        </AddOverlay>
      </Box>
    </Box>
  );
}