import React, { useState } from 'react';
import { Box as MuiBox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Checkbox, Button } from '@mui/material';
import { Box, Item } from '../../Types/Boxes';
import { Link, useNavigate } from 'react-router-dom';

interface ItemDisplayProps {
  boxes: { [key: string] : Box };
}

const ItemsTable: React.FC<ItemDisplayProps> = (props: ItemDisplayProps) => {
  const { boxes } = props;
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredBoxes = Object.values(boxes).filter((box) => 
    box.name.toLowerCase().includes(searchTerm) ||
    box.from.toLowerCase().includes(searchTerm) ||
    (box.to && box.to.toLowerCase().includes(searchTerm)) ||
    (box.items && Object.values(box.items).some((item: Item) => item.name.toLowerCase().includes(searchTerm)))
  );

  const filteredItems = filteredBoxes.map((box) => {
    if (box.items) {
      return Object.values(box.items).map((item: Item) => {
        return {
          ...item,
          box
        }
      })
    }
  }).flat();

  return (  
    <MuiBox>
      <MuiBox display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <TextField
          label="Search Items"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={handleSearchChange}
        />
        </MuiBox>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell>Box Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((item) => {
              if (!item) return;
              return (
              <TableRow key={item.name + item.box.name}>
                <TableCell>{item.name}</TableCell>
                <TableCell><Link to={`/boxes/view/${item.box.id}?callback=/items/view`} style={{ color: "black"}} >{item.box.name}</Link></TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{item.notes}</TableCell>
                <TableCell>
                  {item.tags && item.tags.slice(0, 5).map((tag, index) => (
                    <span key={index}>{(index ? ', ' : '') + tag}</span>
                  ))}
                </TableCell>
              </TableRow>
            )})}
          </TableBody>
        </Table>
      </TableContainer>
    </MuiBox>
  );
};

export default ItemsTable;
