import React from 'react';
import { useSelector } from 'react-redux'
import { Box } from '../../Types/Boxes';
import { RootState } from '../../Types/State';
import ItemsTable from '../../Components/Items/ItemsTable';

const BoxesList: React.FC = () => {
    const boxes: { [key: string]: Box } = useSelector((state: RootState) => state.boxes)

    return (
        <div>
            <ItemsTable boxes={boxes} />
        </div>
    );
};

export default BoxesList;
