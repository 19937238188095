/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getBox = /* GraphQL */ `query GetBox($id: ID!) {
  getBox(id: $id) {
    id
    name
    from
    to
    items {
      name
      quantity
      notes
      tags
      s3Url
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBoxQueryVariables, APITypes.GetBoxQuery>;
export const listBoxes = /* GraphQL */ `query ListBoxes($filter: ModelBoxFilterInput, $limit: Int, $nextToken: String) {
  listBoxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      from
      to
      items {
        name
        quantity
        notes
        tags
        s3Url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListBoxesQueryVariables, APITypes.ListBoxesQuery>;
export const getNote = /* GraphQL */ `query GetNote($id: ID!) {
  getNote(id: $id) {
    id
    title
    category
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetNoteQueryVariables, APITypes.GetNoteQuery>;
export const listNotes = /* GraphQL */ `query ListNotes(
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      category
      content
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListNotesQueryVariables, APITypes.ListNotesQuery>;
