import { configureStore } from '@reduxjs/toolkit'
import boxesSlice from './reducers/boxesSlice'
import systemSlice from './reducers/systemSlice'
import notesSlice from './reducers/notesSlice'

export default configureStore({
  reducer: {
    boxes: boxesSlice,
    system: systemSlice,
    notes: notesSlice
  }
})