import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Navigation, PopupProvider } from  './Components';
import { Home, ItemsList, NotesPage, Qr } from './Pages';

import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import AddIcon from '@mui/icons-material/Add';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import CategoryIcon from '@mui/icons-material/Category';
import QrCodeIcon from '@mui/icons-material/QrCode';

import store from './Redux/store'
import { Provider, useSelector } from 'react-redux'

import { Amplify } from 'aws-amplify';

import amplifyconfig from './amplifyconfiguration.json';

import {
  createBrowserRouter,
  createRoutesFromElements,
  json,
  RouterProvider,
  Routes,
  Route
} from "react-router-dom";

import { BrowserRouter } from "react-router-dom";

import { Authenticator } from '@aws-amplify/ui-react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { 
  BoxesList,
  BoxAdd,
  BoxEdit,
  BoxView
 } from './Pages';

import { withAuthenticator, Button, Heading, UseAuthenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import { AuthUser } from 'aws-amplify/auth';
import { RootState } from './Types/State';
import { Box, CircularProgress } from '@mui/material';

import thunkMiddleware from 'redux-thunk'

export type RouteElement = {
  path: string,
  element: JSX.Element
}

export type MenuItem = {
  name: string,
  icon: any,
} & RouteElement


Amplify.configure(amplifyconfig);

const MENU_ITEMS: MenuItem[] = [
  {
      name: "Home",
      icon: <HomeIcon />,
      path: "/",
      element: <Home />
  },{
      name: "View Boxes",
      icon: <InventoryIcon />,
      path: "/boxes/view",
      element: <BoxesList />
  },{
      name: "View Items",
      icon: <TurnedInIcon />,
      path: "/items/view",
      element: <ItemsList />
  },{
      name: "Add Box",
      icon: <AddIcon />,
      path: "/boxes/add",
      element: <BoxAdd />
  },{
      name: "Move Items",
      icon: <DriveFileMoveIcon />,
      path: "/items/move",
      element: <Home />
  },{
      name: "Notes",
      icon: <CategoryIcon />,
      path: "/notes/view",
      element: <Home />
  },{
      name: "QR Generator",
      icon: <QrCodeIcon />,
      path: "/qr",
      element: <Qr />
  }
]

const additionalPaths: RouteElement[] = [
  {
    path: "/boxes/view/:id",
    element: <BoxView />
  },{
    path: "/boxes/edit/:id",
    element: <BoxEdit />
  },
]

// const routerItems = MENU_ITEMS.map(({path, component}) => {
//   return {
//     path,
//     element: component
//   }
// })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

type AppProps = {
  signOut?: UseAuthenticator["signOut"];
  user?: AuthUser;
};

const App = (props: AppProps) => (
  <Provider store={store}>
    <BrowserRouter>
      <PopupProvider>
        <Navigation menuItems={MENU_ITEMS} user={props.user} signOut={props.signOut}>
          <Routes>
            {[...MENU_ITEMS, ...additionalPaths].map((item, index) => {
              return (
                <Route key={index} path={item.path} element={item.element} />
              )
              })
            }
          </Routes>
        </Navigation>
      </PopupProvider>
    </BrowserRouter>
  </Provider>
)

const AuthenticatedApp = withAuthenticator(App);

root.render(
  <React.StrictMode>
    <AuthenticatedApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
