import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Note } from '../../Types/Notes';
import { Button } from '@mui/material';

interface NotesDrawerProps {
  notes: Note[];
  open: boolean;
  onToggle: () => void;
  onNoteSelect: (noteId: string) => void;
  onCreateNewNote: () => void;
}

const NotesDrawer: React.FC<NotesDrawerProps> = ({ notes, open, onToggle, onNoteSelect, onCreateNewNote }) => {
  const [selectedNotes, setSelectedNotes] = useState<Set<string>>(new Set());

  const handleToggle = (noteId: string) => {
    setSelectedNotes(prev => {
      const newSelected = new Set(prev);
      if (newSelected.has(noteId)) {
        newSelected.delete(noteId);
      } else {
        newSelected.add(noteId);
      }
      return newSelected;
    });
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={onToggle} style={{ zIndex: 1300 }}>
      <Button onClick={onCreateNewNote} style={{ margin: '10px' }}>Create New Note</Button> {/* Add this button */}
      <List>
        {notes.map(note => (
          <ListItem key={note.id} button onClick={() => onNoteSelect(note.id)}>
            {/* <Checkbox
              checked={selectedNotes.has(note.id)}
              onChange={() => handleToggle(note.id)}
            /> */}
            <ListItemText primary={note.title} secondary={note.category} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default NotesDrawer;
