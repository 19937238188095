import React, { useEffect, useRef } from 'react';
import { BrowserQRCodeReader } from '@zxing/browser';
import { Box, Paper } from '@mui/material';

interface QRScannerProps {
  onScan: (data: string) => void;
  onError: (error: Error) => void;
}

export const QRScanner: React.FC<QRScannerProps> = ({ onScan, onError }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    const codeReader = new BrowserQRCodeReader();

    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
      .then(stream => {
        streamRef.current = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          codeReader.decodeFromVideoElement(videoRef.current, (result, error) => {
            if (result) {
              onScan(result.getText());
            } else if (error) {
              if (JSON.stringify(error) !== "{}") {
                onError(error);
              }
            }
          });
        }
      })
      .catch(onError);

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    };
  }, [onScan, onError]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', position: 'relative' }}>
      <Paper elevation={3} sx={{ padding: 2, position: 'relative' }}>
        <video
          ref={videoRef}
          style={{ width: '100%' }}
          autoPlay
          muted
          playsInline
        />
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '60%',
          height: '60%',
          transform: 'translate(-50%, -50%)',
          border: '2px solid #fff',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          zIndex: 10,
          pointerEvents: 'none',
        }} />
      </Paper>
    </Box>
  );
};
