import React, { useState } from 'react';
import { Box as MuiBox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Checkbox, Button } from '@mui/material';
import { Box, Item } from '../../Types/Boxes';
import { Link, useNavigate } from 'react-router-dom';

interface BoxDisplayProps {
  boxes: { [key: string] : Box };
  deleteBoxes: (ids: string[]) => boolean;
}

const BoxesTable: React.FC<BoxDisplayProps> = (props: BoxDisplayProps) => {
  const { boxes, deleteBoxes } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBoxes, setSelectedBoxes] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleSelectBox = (id: string) => {
    const selectedIndex = selectedBoxes.indexOf(id);
    let newSelectedBoxes: string[] = [];

    if (selectedIndex === -1) {
      newSelectedBoxes = newSelectedBoxes.concat(selectedBoxes, id);
    } else if (selectedIndex === 0) {
      newSelectedBoxes = newSelectedBoxes.concat(selectedBoxes.slice(1));
    } else if (selectedIndex === selectedBoxes.length - 1) {
      newSelectedBoxes = newSelectedBoxes.concat(selectedBoxes.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBoxes = newSelectedBoxes.concat(
        selectedBoxes.slice(0, selectedIndex),
        selectedBoxes.slice(selectedIndex + 1),
      );
    }

    setSelectedBoxes(newSelectedBoxes);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredBoxes = Object.values(boxes).filter((box) => 
    box.name.toLowerCase().includes(searchTerm) ||
    box.from.toLowerCase().includes(searchTerm) ||
    (box.to && box.to.toLowerCase().includes(searchTerm)) ||
    (box.items && Object.values(box.items).some((item: Item) => item.name.toLowerCase().includes(searchTerm)))
  );

  const handleEdit = () => {
    navigate(`/boxes/edit/${selectedBoxes[0]}`);
  };

  const handleDelete = () => {
    deleteBoxes(selectedBoxes);
    setSelectedBoxes([]);
  };

  return (  
    <MuiBox>
      <MuiBox display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <TextField
          label="Search Boxes"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={handleSearchChange}
        />
        </MuiBox>
        <MuiBox display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <div>
          <Button
            color="primary"
            variant="contained"
            disabled={selectedBoxes.length !== 1}
            style={{ marginRight: '10px' }}
            onClick={handleEdit}
          >
            Edit
          </Button>
          <Button
            color="secondary"
            variant="contained"
            disabled={selectedBoxes.length === 0}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </MuiBox>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedBoxes.length > 0 && selectedBoxes.length < filteredBoxes.length}
                  checked={filteredBoxes.length > 0 && selectedBoxes.length === filteredBoxes.length}
                  onChange={(event) => setSelectedBoxes(event.target.checked ? filteredBoxes.map((box) => box.id) : [])}
                />
              </TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>From</TableCell>
              <TableCell>Items</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBoxes.map((box) => (
              <TableRow key={box.id} selected={selectedBoxes.indexOf(box.id) !== -1}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedBoxes.indexOf(box.id) !== -1}
                    onChange={() => handleSelectBox(box.id)}
                  />
                </TableCell>
                <TableCell><Link to={`/boxes/view/${box.id}`} style={{ color: "black"}} >{box.id}</Link></TableCell>
                <TableCell><Link to={`/boxes/view/${box.id}`} style={{ color: "black"}} >{box.name}</Link></TableCell>
                <TableCell>{box.from}</TableCell>
                <TableCell>
                  {box.items && Object.entries(box.items).slice(0, 5).map(([_, item], index) => (
                      <span key={index}>{(index ? ', ' : '') + item.name}</span>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MuiBox>
  );
};

export default BoxesTable;
