/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createBox = /* GraphQL */ `mutation CreateBox(
  $input: CreateBoxInput!
  $condition: ModelBoxConditionInput
) {
  createBox(input: $input, condition: $condition) {
    id
    name
    from
    to
    items {
      name
      quantity
      notes
      tags
      s3Url
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBoxMutationVariables,
  APITypes.CreateBoxMutation
>;
export const updateBox = /* GraphQL */ `mutation UpdateBox(
  $input: UpdateBoxInput!
  $condition: ModelBoxConditionInput
) {
  updateBox(input: $input, condition: $condition) {
    id
    name
    from
    to
    items {
      name
      quantity
      notes
      tags
      s3Url
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBoxMutationVariables,
  APITypes.UpdateBoxMutation
>;
export const deleteBox = /* GraphQL */ `mutation DeleteBox(
  $input: DeleteBoxInput!
  $condition: ModelBoxConditionInput
) {
  deleteBox(input: $input, condition: $condition) {
    id
    name
    from
    to
    items {
      name
      quantity
      notes
      tags
      s3Url
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBoxMutationVariables,
  APITypes.DeleteBoxMutation
>;
export const createNote = /* GraphQL */ `mutation CreateNote(
  $input: CreateNoteInput!
  $condition: ModelNoteConditionInput
) {
  createNote(input: $input, condition: $condition) {
    id
    title
    category
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNoteMutationVariables,
  APITypes.CreateNoteMutation
>;
export const updateNote = /* GraphQL */ `mutation UpdateNote(
  $input: UpdateNoteInput!
  $condition: ModelNoteConditionInput
) {
  updateNote(input: $input, condition: $condition) {
    id
    title
    category
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNoteMutationVariables,
  APITypes.UpdateNoteMutation
>;
export const deleteNote = /* GraphQL */ `mutation DeleteNote(
  $input: DeleteNoteInput!
  $condition: ModelNoteConditionInput
) {
  deleteNote(input: $input, condition: $condition) {
    id
    title
    category
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNoteMutationVariables,
  APITypes.DeleteNoteMutation
>;
