import React from 'react';
import { useDispatch } from 'react-redux'
import { Box } from '../../Types/Boxes';
import { useNavigate } from 'react-router-dom';
import { BoxMutate } from '../../Components';
import { createBoxPersistence } from '../../Redux/reducers/boxesSlice';

const BoxesEdit: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSave = (box: Box) => {
    dispatch(createBoxPersistence(box));
    navigate(`/boxes/view/${box.id}`)
    return true;
  }

  return (
      <div>
          <BoxMutate save={handleSave}/>
      </div>
  );
};

export default BoxesEdit;
