import React, { useState, ChangeEvent } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Paper, Typography, Box as MuiBox, Grid } from '@mui/material';
import { Box, Item } from '../../Types/Boxes';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { usePopup } from '../General/PopUpContext';
import { QRScanner } from '../General/QRScanner';

interface BoxMutateProps {
  selected?: Box;
  save: (box: Box) => boolean;
}

const BoxMutate: React.FC<BoxMutateProps> = ({ selected, save }: BoxMutateProps) => {
  const location = useLocation();
  const startingState = selected ? selected : { id: uuidv4(), name: '', from: '', to: '', items: {} };
  const [box, setBox] = useState<Box>(startingState);
  const [showQrReader, setShowQrReader] = useState<boolean>(false);
  const [idFieldEnabled, setIdFieldEnabled] = useState<boolean>(true);

  const [item, setItem] = useState<Item>({ name: '', quantity: 1, notes: '', tags: [] });
  const [items, setItems] = useState<{ [key: string]: Item }>(startingState.items ? startingState.items : {});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const [editingItem, setEditingItem] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBox({ ...box, [e.target.name]: e.target.value });
  };

  const handleItemChange = (e: ChangeEvent<HTMLInputElement>) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };

  const handleAddSaveItem = () => {
    const name = item.name.trim();

    if (!name) {
      return
    }

    const id = name.toLowerCase();

    if (editingItem) {
      if (selectedItems[0] !== id) {
        // This means we are changing name. Verify we aren't changing it to something else in the list
        if (items[id]) {
          alert("You cannot choose the same name as another item. Edit that item.")
          return;
        } else {
          // Not the same name, so delete the old and make a new
          const newItems = {...items};
          delete newItems[selectedItems[0]]
          newItems[id] = item;

          setBox({ ...box, items: { ...newItems } });
          setItems({ ...newItems });
          setItem({ name: '', quantity: 1, notes: '', tags: [] }); // Reset form
          setEditingItem(false);
        }
      } else {
        // Editing the existing item
        setBox({ ...box, items: { ...box.items, [id]: item } });
        setItems({ ...items, [id]: item });
        setItem({ name: '', quantity: 1, notes: '', tags: [] }); // Reset form
        setEditingItem(false);
      }
    } else if (!items[id]) {
      setBox({ ...box, items: { ...box.items, [id]: item } });
      setItems({ ...items, [id]: item });
      setItem({ name: '', quantity: 1, notes: '', tags: [] }); // Reset form
      setEditingItem(false);
    } else {
      alert("You cannot choose the same name as another item.s")
    }

    setSelectedItems([])
  };

  const handleClearCancelItem = () => {
    setItem({ name: '', quantity: 1, notes: '', tags: [] });
    setEditingItem(false);
  };

  const handleSelectItem = (name: string) => {
    const newSelectedItems = selectedItems.includes(name)
      ? selectedItems.filter(itemName => itemName !== name)
      : [...selectedItems, name];
    setSelectedItems(newSelectedItems);
  };

  const handleDeleteItems = () => {
    const newItems = { ...items };
    selectedItems.forEach(itemName => {
      delete newItems[itemName];
    });
    setItems(newItems);
    setSelectedItems([]);
  };

  const handleEditItem = () => {
    if (selectedItems.length === 1) {
      const itemToEdit = items[selectedItems[0]];
      setItem(itemToEdit);
      setEditingItem(true);
    }
  };

  const { showMessage } = usePopup();

  const handleQRCodeScanned = (data: string) => {
    showMessage(`QR Code Data: ${JSON.stringify(data)}`, 'info', 6000);
    setBox({ ...box, id: data });
    setShowQrReader(false);
  };

  function isObjectEmpty(obj: Object) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  const handleQRCodeError = (error: Error) => {
    if (error && !isObjectEmpty(error) ) {
      showMessage(`QR Code Scan Error: ${JSON.stringify(error)}`, 'error', null);
    }
  };
  
  const handleSave = () => {
    save({
      ...box,
      items
    });
    showMessage("Saved", "info", 3000);
    handleCancel();
  };

  const handleCancel = () => {
    const queryParams = new URLSearchParams(location.search);
    const callback = queryParams.get('callback');
    navigate(`/boxes/view/${box.id}${callback ? `?callback=${callback}` : ''}`);
  };

  if (showQrReader) {
    return (
      <div>
        <MuiBox display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="outlined" color="secondary" onClick={() => setShowQrReader(false)} style={{ marginRight: '10px' }}>
          Cancel
        </Button>
        <QRScanner onScan={handleQRCodeScanned} onError={handleQRCodeError}/>
      </MuiBox>
      </div>
    )
  }

  return (
    <div>
      <MuiBox display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="contained" color="success" onClick={() => setShowQrReader(true)}>
          Scan QR
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleCancel} style={{ marginRight: '10px' }}>
          Cancel
        </Button>
      </MuiBox>
      {/* Box Input Fields */}
      <Typography variant="h6">Box Details</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField disabled={idFieldEnabled} onDoubleClick={() => setIdFieldEnabled(!idFieldEnabled)} label="Box ID" name="id" fullWidth value={box.id} onChange={handleBoxChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Box Name" name="name" fullWidth value={box.name} onChange={handleBoxChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="From" name="from" fullWidth value={box.from} onChange={handleBoxChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="To" name="to" fullWidth value={box.to} onChange={handleBoxChange} />
        </Grid>
      </Grid>

      {/* Item Input Fields */}
      <Typography variant="h6" style={{ marginTop: '20px' }}>Add Item to Box</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <TextField label="Name" name="name" fullWidth value={item.name} onChange={handleItemChange} />
        </Grid>
        <Grid item xs={6} md={1}>
          <TextField label="Quantity" name="quantity" type="number" fullWidth value={item.quantity} onChange={handleItemChange} />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField label="Notes" name="notes" fullWidth value={item.notes} onChange={handleItemChange} />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField label="Tags" name="tags" fullWidth value={item.tags?.join(', ')} onChange={e => setItem({ ...item, tags: e.target.value.split(',').map(tag => tag.trim()) })} />
        </Grid>
        <Grid item md={1}>
          <Button disabled={item.name.length == 0} onClick={handleAddSaveItem}>{editingItem ? "Save" : "Add"}</Button>
          <Button onClick={handleClearCancelItem}>{editingItem ? "Cancel" : "Clear"}</Button>
        </Grid>
      </Grid>

      {/* Items Table */}
      <div  style={editingItem ? {
        pointerEvents: "none",
        opacity: 0.4
      } : {}}>
      <Button onClick={handleDeleteItems} disabled={selectedItems.length === 0}>Delete Selected</Button>
      <Button onClick={handleEditItem} disabled={selectedItems.length !== 1}>Edit Selected</Button>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(items).map((item, index) => (
              <TableRow key={index}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedItems.includes(item.name.toLowerCase())}
                    onChange={() => handleSelectItem(item.name.toLowerCase())}
                  />
                </TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{item.notes}</TableCell>
                <TableCell>{item.tags?.join(', ')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      </div>
    </div>
  );
};

export default BoxMutate;
