import React, { createContext, useContext, useState } from 'react';
import { PopupCard } from './PupUpCard';

interface PopupContextData {
  showMessage: (message: string, severity: 'error' | 'info', timeout: number | null) => void;
}

const PopupContext = createContext<PopupContextData>({} as PopupContextData);

export const usePopup = () => useContext(PopupContext);

interface PopupProviderProps {
  children: React.ReactNode;
}

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<'error' | 'info'>('info');
  const [timeout, setTimeout] = useState<number | null>(6000);

  const showMessage = (msg: string, sev: 'error' | 'info', timeout: number | null) => {
    setMessage(msg);
    setSeverity(sev);

    if (timeout) {
      setTimeout(timeout);
    } else if (sev === 'error') {
      setTimeout(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PopupContext.Provider value={{ showMessage }}>
      {children}
      <PopupCard open={open} message={message} severity={severity} handleClose={handleClose} timeout={timeout}/>
    </PopupContext.Provider>
  );
};
