import { TextField } from '@mui/material';
import React, { useState, useRef } from 'react';
import QRCode from "react-qr-code";
//import QRCode from 'qrcode.react';
import { jsPDF } from 'jspdf';
import { usePopup } from '../../Components/General/PopUpContext';
import { QRScanner } from '../../Components';

function Qr() {
  // const [content, setContent] = useState("hey");


  // return (
  //   <div className="App">
  //     <QRCode value={content} />
  //     <TextField id="outlined-basic" label="Outlined" variant="outlined" value={content} onChange={(e) => setContent(e.target.value)}/>
  //   </div>
  // );
  const { showMessage } = usePopup();

  const qrCodes = [
    { id: 1, value: '12345' },
    { id: 2, value: '67890' },
    // ... add more QR codes as needed
  ];

  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    if (!printRef || !printRef.current) return;

    const content = printRef.current.innerHTML;
    const printWindow = window.open('', '', 'height=600,width=800');

    if (!printWindow) return;
    
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  // const generatePDF = () => {
  //   const pdf = new jsPDF();

  //   if (!pdfRef.current) {
  //     return
  //   }

  //   const qrCodeElements = pdfRef.current.querySelectorAll('.qr-code-item');

  //   qrCodeElements.forEach((node, index) => {
  //     const canvas = node.querySelector('canvas');
  //     if (!canvas) return;
  //     const qrCodeDataUrl = canvas.toDataURL("image/jpeg");

  //     // Adjust these values based on your layout requirements
  //     const xPosition = (index % 2) * 100; // Example for 2-column layout
  //     const yPosition = Math.trunc(index / 2) * 100; // Example for 2-column layout

  //     pdf.addImage(qrCodeDataUrl, 'JPEG', xPosition, yPosition, 80, 80);
  //     if (index < qrCodeElements.length - 1) {
  //       pdf.addPage();
  //     }
  //   });

  //   pdf.save('qr-codes.pdf');
  // };

  const handleQRCodeScanned = (data: string) => {
    showMessage(`QR Code Data: ${JSON.stringify(data)}`, 'info', 6000);
  };

  function isObjectEmpty(obj: Object) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  const handleQRCodeError = (error: Error) => {
    if (error && !isObjectEmpty(error) ) {
      showMessage(`QR Code Scan Error: ${JSON.stringify(error)}`, 'error', null);
    }
  };


  return (
    <>
    <div>
      <h1>QR Code Scanner</h1>
      <QRScanner onScan={handleQRCodeScanned} onError={handleQRCodeError} />
    </div>
    <div ref={printRef}>
      {qrCodes.map(qr => (
        <div key={qr.id} className="qr-code-item">
          <QRCode value={qr.value} />
          <p>{qr.value}</p>
        </div>
      ))}
      <button onClick={handlePrint}>Export to Print</button>
    </div>
    </>
  );
}

export default Qr;