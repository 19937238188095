import React from 'react';
import { useSelector } from 'react-redux'
import { Box } from '../../Types/Boxes';
import { RootState } from '../../Types/State';
import BoxesCardsDisplay from '../../Components/Boxes/BoxesCardsDisplay';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

const BoxView: React.FC = () => {
  const boxes: { [key: string]: Box } = useSelector((state: RootState) => state.boxes)
  const { id } = useParams<{ id: string }>();
  const box = id ? boxes[id] : undefined;

  if (!box) {
    return (
        <div style={{ padding: '20px' }}>
            <Typography variant="h4">
                Box not found. The page could also still be loading.
            </Typography>
            {/* Optionally, add more Material-UI components to enhance the 'not found' message */}
        </div>
    );
  }

  return (
      <div>
          <BoxesCardsDisplay boxSingular={box} />
      </div>
  );
};

export default BoxView;
