import React from 'react';
import { Typography, List, ListItem, Divider, Paper, Box as MuiBox, Button } from '@mui/material';
import { Box, Item } from '../../Types/Boxes';
import { useLocation, useNavigate } from 'react-router-dom';

interface BoxDisplayProps {
  boxes?: { [key: string] : Box };
  boxSingular?: Box;
}

function BoxesCardsDisplay({boxes, boxSingular }: BoxDisplayProps) {
  const navigate = useNavigate();
  const location = useLocation();
  
  if (boxes) {
    return (
      <div>
        {Object.entries(boxes).map((box: [string, Box]) => (
          <Paper key={box[0]} style={{ margin: '16px', padding: '16px' }}>
            <Typography variant="h6">{box[1].name}</Typography>
            <Typography variant="subtitle1">From: {box[1].from}</Typography>
            {box[1].to && <Typography variant="subtitle1">To: {box[1].to}</Typography>}
            
            <Divider style={{ margin: '8px 0' }} />

            <List>
              {box[1].items && Object.entries(box[1].items).map((item: [string, Item], index: number) => (
                <ListItem key={index}>
                  <Typography>
                    {item[1].name} {item[1].quantity && `x${item[1].quantity}`}
                    {item[1].notes && ` - ${item[1].notes}`}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Paper>
        ))}
      </div>
    );
              }
  
  if (boxSingular) {
    const box: Box = boxSingular;

    const handleEdit = () => {
      const queryParams = new URLSearchParams(location.search);
      const callback = queryParams.get('callback');
      navigate(`/boxes/edit/${box.id}${callback ? `?callback=${callback}` : ''}`);
    };
  
    const handleBack = () => {
      const queryParams = new URLSearchParams(location.search);
      const myParam = queryParams.get('callback');
      if (myParam) {
        navigate(myParam);
        return;
      }
      navigate(`/boxes/view`);
    };

    return (
      <>
      <MuiBox display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" color="primary" onClick={handleEdit}>
          Edit
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleBack} style={{ marginRight: '10px' }}>
          Back
        </Button>
      </MuiBox>
     
    <Paper key={box.name} style={{ margin: '16px', padding: '16px' }}>
      <Typography variant="h6">{box.name}</Typography>
      <Typography variant="subtitle1">From: {box.from}</Typography>
      {box.to && <Typography variant="subtitle1">To: {box.to}</Typography>}
      
      <Divider style={{ margin: '8px 0' }} />

      <List>
        {box.items && Object.entries(box.items).map((item: [string, Item], index: number) => (
          <ListItem key={index}>
            <Typography>
              {item[1].name} {item[1].quantity && `x${item[1].quantity}`}
              {item[1].notes && ` - ${item[1].notes}`}
            </Typography>
          </ListItem>
        ))}
      </List>
      </Paper>
      </>
    );
  }

  return (
    <div>
      <Typography variant="h4" color="error">
        No box or boxes provided to component.
      </Typography>
    </div>
  )
};

export default BoxesCardsDisplay;
